import React from 'react';
import 'shaka-player/dist/controls.css';
import styled from "@emotion/styled";
import { toBase64 } from '../helper';
// eslint-disable-next-line
const shaka = require('shaka-player/dist/shaka-player.ui.js');
shaka.net.NetworkingEngine.registerScheme('blob', shaka.net.HttpFetchPlugin);

const StyledVideo = styled.video`
  max-width: 90vw;
  // 50px is the size of lightboxes's topbar
  height: calc(90vh - 50px);
  margin: auto;
`

const onError = (error) => {
  // eslint-disable-next-line no-console
  console.error('Error code', error.code, 'object', error);
}

const ShakaPlayer = ({ file, manifestUri, posterUri, videoRef, type }) => {
  const videoContainerRef = React.useRef();
  const [videoSrc, setVideoSrc] = React.useState(null);

  React.useEffect(() => {
    if (type.includes('quicktime')) {
      toBase64(file).then((src) => {
        setVideoSrc(src);      
      });
    }
  }, [file, type]);

  React.useEffect(() => {
    const video = videoRef.current;

    if (type.includes('quicktime')) {
      // fall back to HTML5 video player
      if (videoSrc) {
        video.controls = true;
        video.controlsList = 'nodownload nofullscreen';

        const source = document.createElement('source');
        source.src = videoSrc;
        source.type = 'video/mp4'; // force type to mp4 for <video> to be able to render content
        video.appendChild(source);
      }
    } else {
      const player = new shaka.Player(video);
  
      const uiConfig = {
        controlPanelElements: ['mute', 'volume', 'time_and_duration', 'fullscreen']
      };
      const ui = new shaka.ui.Overlay(player, videoContainerRef.current, video);
      ui.configure(uiConfig, videoContainerRef.current);
      ui.getControls();

      player.addEventListener('error', (error) => onError(error.detail));
      player.load(manifestUri, null, type).catch(onError);
    }
  }, [type, videoSrc, manifestUri, videoRef]);

  return(
    <div ref={videoContainerRef}>
      <StyledVideo
        className="shaka-video"
        ref={videoRef}
        poster={posterUri}
      />
    </div>
  );
}

export default ShakaPlayer;
