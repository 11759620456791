import daDK from './da-DK/strings.json';
import deAT from './de-AT/strings.json';
import deCH from './de-CH/strings.json';
import deDE from './de-DE/strings.json';
import enAU from './en-AU/strings.json';
import enCA from './en-CA/strings.json';
import enGB from './en-GB/strings.json';
import enIE from './en-IE/strings.json';
import enNZ from './en-NZ/strings.json';
import enUS from './en-US/strings.json';
import enZA from './en-ZA/strings.json';
import esES from './es-ES/strings.json';
import fiFI from './fi-FI/strings.json';
import frBE from './fr-BE/strings.json';
import frFR from './fr-FR/strings.json';
import itIT from './it-IT/strings.json';
import jaJP from './ja-JP/strings.json';
import nbNO from './nb-NO/strings.json';
import nlBE from './nl-BE/strings.json';
import nlNL from './nl-NL/strings.json';
import plPL from './pl-PL/strings.json';
import ptBR from './pt-BR/strings.json';
import ptPT from './pt-PT/strings.json';
import ruRU from './ru-RU/strings.json';
import svSE from './sv-SE/strings.json';

export default {
  'da-DK': daDK,
  'de-AT': deAT,
  'de-CH': deCH,
  'de-DE': deDE,
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  'en-IE': enIE,
  'en-NZ': enNZ,
  'en-US': enUS,
  'en-ZA': enZA,
  'es-ES': esES,
  'fi-FI': fiFI,
  'fr-BE': frBE,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'nb-NO': nbNO,
  'nl-BE': nlBE,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'sv-SE': svSE,
};
