/* eslint no-undefined: "off", consistent-return: "off" */

import { bootstrap } from './analytics-bootstrap.js';
import { sha1 } from './sha1.js';

const testUsersEmail = ['@mg.tp-qa-mail.com', '@tp-automatedtesting.com', '@martinbuberl.com', '@yopmail.com'];

const getIdFromEmail = (email) => {
    email = email.toLowerCase();
    return sha1().hash(`${email}TrustpilotTackingHasherIsCool`);
};

function getEmailAddress() {
    const user = window.analytics.user && window.analytics.user();
    if (!user) {
        return;
    }

    const traits = user.traits();
    if (!traits) {
        return;
    }

    return traits.email;
}

function isTestUser(email) {
    email = email || getEmailAddress();

    if (!email) {
        return false;
    }

    const isTestEmail = testUsersEmail.some(function (testEmail) {
        return RegExp(`${testEmail}$`, 'i').test(email);
    });

    return isTestEmail;
}

function getOptions(options = {}, email) {
    if (!isTestUser(email)) {
        return options;
    }
    return {
        integrations: {
            'All': false
        },
        ...options
    };
}

export default class Library {
    constructor(writeKey) {
        bootstrap(writeKey);
        this.analytics.page(null, null, null, getOptions());
    }
    
    // eslint-disable-next-line class-methods-use-this
    get analytics() {
        return window.analytics;
    }

    track(event, properties, options, callback) {
        if (typeof properties === 'function') {
            callback = properties;
            properties = {};
        } else if (typeof options === 'function') {
            callback = options;
            options = undefined;
        }

        this.analytics.track(event, properties, getOptions(options), callback);
    }

    trackLink(element, event, properties) {
        this.analytics.trackLink(element, event, properties);
    }

    alias(email, previousId, options, callback) {
        if (typeof options === 'function') {
            callback = options;
            options = undefined;
        }

        this.analytics.alias(getIdFromEmail(email), previousId, getOptions(options, email), callback);
    }

    identify(email, traits, options, callback) {
        if (typeof traits === 'function') {
            callback = traits;
            traits = {};
        } else if (typeof options === 'function') {
            callback = options;
            options = undefined;
        }

        traits = traits || {};
        traits.email = traits.email || email;

        this.analytics.identify(getIdFromEmail(email), traits, getOptions(options, email), callback);
    }

    aliasAndIdentify(email, traits, callback) {
        const emailId = getIdFromEmail(email);
        this.analytics.alias(emailId, null, null, () => {
            this.analytics.identify(emailId, traits, null, callback);
        });
    }

    reset() {
        this.analytics.reset();
    }

    group(groupId, traits, options, callback) {
        this.analytics.group(groupId, traits, options, callback);
    }

    page(category, name, properties, options, callback) {
        this.analytics.page(category, name, properties, options, callback);
    }
}
