import styled from '@emotion/styled';
import moment from 'moment';
import { Text } from 'ilenia';
import React from 'react';

const StyledH2 = styled.h2`
  font-size: 1.25rem;
  line-height: 1.5625rem;
  margin: 1.125rem auto 0.25rem !important;

  @media screen and (min-width: 48rem) {
    font-size: 2rem;
    line-height: 3rem;
    margin: 2.375rem 0 1.5rem !important;
  }
`;

const StyledModerationDetails = styled.div`
  color: #454554;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  line-height: 1.5rem;

  @media screen and (min-width: 48rem) {
    font-size: 1rem;
  }
`;

export const ModerationHeader = ({
  businessName,
  productName,
  reviewDate,
}) => (
  <React.Fragment>
    <StyledH2>
      <Text id="productreviews-evaluatepage.edit.moderationrequest" />
    </StyledH2>
    <div>
      <StyledModerationDetails>
        <Text
          id="productreviews-evaluatepage.edit.moderationrequestdetails"
          interpolations={{
            BUSINESSUNIT: businessName,
            DATE: moment(reviewDate).format('LL'),
            PRODUCT: productName,
          }}
        />
      </StyledModerationDetails>
    </div>
  </React.Fragment>
);
