import React from 'react';
import posed from 'react-pose';

const Container = posed.section();

class PageSection extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const classNames = `section section-${
      //
      this.props.active ? 'active' : 'inactive'
    } section-${
      //
      this.props.visible ? 'visible' : 'hidden'
    }`;

    return <Container className={classNames}>{this.props.children}</Container>;
  }
}

export default PageSection;
