import styled from '@emotion/styled';
import { HtmlText } from 'ilenia';
import React, { Component } from 'react';
import posed from 'react-pose';
import scrollToComponent from 'react-scroll-to-component';
import * as api from '../api';
import { fallbackImageUrls } from '../helper';
import Attributes from './Attributes';
import PageSection from './PageSection';
import Rating from './Rating';
import ReviewContent from './ReviewContent';
import Submit from './Submit';

const StyledReportDetails = styled.div`
  background-color: #faeca5;
  border-radius: 0.125rem 0.125rem 0 0;
  color: #454554;
  display: flex;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  line-height: 1.25rem;
  padding: 1rem 1rem 1rem 1.5rem;
`;

const StyledReportIcon = styled.div`
  margin-right: 1rem;
`;

const ExpandableContent = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' },
});

class Review extends Component {
  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();
    this.promisesRef = React.createRef();
    this.promisesRef.current = {};

    const {
      attachments = [],
      attributeRatings = [],
      content = '',
      existing,
      productImages = [],
      stars = null,
    } = props;

    this.state = {
      acceptsTermsChanged: false,
      attachments: attachments.reduce((output, { id, processedFiles = [], state }, index) => {
        const original = processedFiles.find(
          (file) => file.dimension === 'original' || file.mimeType.startsWith('video')
        );
        const isVideo = original?.mimeType.startsWith('video');
        const src = original ? original.url : fallbackImageUrls[index % fallbackImageUrls.length];
        const thumbnailSrc = isVideo
          ? processedFiles.find((file) => file.dimension === 'thumbnail')?.url
          : src;
        return {
          ...output,
          [id]: {
            id,
            name: id,
            // FIXME User photo placeholder images
            src,
            thumbnailSrc,
            state,
            type: original?.mimeType,
          },
        };
      }, {}),
      attributeRatings,
      content,
      contentChanged: false,
      error: null,
      loading: false,
      productImages,
      scrolledToReviewContent: existing,
      stars,
      pristine: true,
      uploadState: {},
      promiseState: {},
    };

    this.areAttributeRatingsValid = this.areAttributeRatingsValid.bind(this);
    this.checkScrollToReviewContent = this.checkScrollToReviewContent.bind(this);
    this.editReview = this.editReview.bind(this);
    this.isReviewValid = this.isReviewValid.bind(this);
    this.scrollToReviewContent = this.scrollToReviewContent.bind(this);
    this.scrollToBeginning = this.scrollToBeginning.bind(this);
    this.setAcceptsTerms = this.setAcceptsTerms.bind(this);
    this.setAttachments = this.setAttachments.bind(this);
    this.setAttributeNotApplicable = this.setAttributeNotApplicable.bind(this);
    this.setAttributeRating = this.setAttributeRating.bind(this);
    this.setContent = this.setContent.bind(this);
    this.setPastedContentLength = this.setPastedContentLength.bind(this);
    this.setRating = this.setRating.bind(this);
    this.submit = this.submit.bind(this);
    this.trackPostedProductReview = this.trackPostedProductReview.bind(this);
    this.uploadAttachments = this.uploadAttachments.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.waitForAttachments = this.waitForAttachments.bind(this);
    this.setUploadState = this.setUploadState.bind(this);
  }

  componentDidMount() {
    if (this.props.isProductSelected) {
      this.scrollToBeginning(1600);
    }
  }

  render() {
    const {
      acceptsTerms,
      attachmentsEnabled,
      consumerEmail,
      consumerName,
      consumerUserExists,
      desktop,
      editMode,
      existing,
      fallbackImageUrl,
      ip,
      locale,
      minimumReviewLength,
      name,
      productId,
      productImages,
      productType,
      track,
    } = this.props;
    const {
      acceptsTermsChanged,
      attributeRatings,
      attachments,
      content,
      contentChanged,
      loading,
      pristine,
      stars,
      triedSubmit,
      uploadState,
    } = this.state;

    const hasStars = (stars || 0) > 0;
    const attributesActive = desktop || hasStars;
    const reviewContentActive = desktop || (attributesActive && this.areAttributeRatingsValid());

    const hasRejectedAttachment = Object.values(attachments).some(
      (attachment) => attachment.state === 'VerificationRejected'
    );

    return (
      <React.Fragment>
        {hasRejectedAttachment && (
          <StyledReportDetails>
            <StyledReportIcon>
              <img
                alt="" // we don't need alt text here as the image is decorative and not informative (i.e. you wouldn't miss any info if it was removed from the page)
                src="https://productreviews-evaluatepage.trustpilot.com/assets/Report_icon.svg"
                title="Reported" // FIXME i18n
              />
            </StyledReportIcon>
            <div>
              <HtmlText
                id="productreviews-evaluatepage.edit.reportreason"
                interpolations={{
                  REASON: `<b>The photo is inappropriate</b>`, // FIXME This should be replaced by a proper report translation key
                }}
              />
            </div>
          </StyledReportDetails>
        )}
        <PageSection active visible ref={(ref) => (this.mainRatingRef = ref)}>
          <Rating
            editReview={this.editReview}
            fallbackImageUrl={fallbackImageUrl}
            name={name}
            productImages={productImages}
            productType={productType}
            setRating={this.setRating}
            showThankYouFeedback={existing && pristine}
            stars={stars}
            loading={loading}
          />
        </PageSection>
        <ExpandableContent
          className="expandable-reviewcontent"
          pose={!this.state.pristine ? 'open' : 'closed'}
        >
          {productType === 'product' && attributeRatings.length > 0 ? (
            <PageSection
              active={attributesActive}
              ref={(ref) => (this.attributesRef = ref)}
              visible={hasStars}
            >
              <Attributes
                attributeRatings={attributeRatings}
                attributeRatingsMessageVisible={!this.areAttributeRatingsValid() && triedSubmit}
                isMs={this.props.isMs}
                keyPrefix={productId}
                setNotApplicable={this.setAttributeNotApplicable}
                setRating={this.setAttributeRating}
                loading={loading}
              />
            </PageSection>
          ) : (
            <div className="white-card no-attributes" />
          )}
          <PageSection
            active={reviewContentActive}
            ref={(ref) => (this.reviewContentRef = ref)}
            visible={hasStars}
          >
            <ReviewContent
              attachments={attachments}
              attachmentsEnabled={attachmentsEnabled}
              characterCountHighlighted={triedSubmit && !contentChanged}
              characterCountVisible={
                (triedSubmit || content.length > 0) && content.length < minimumReviewLength
              }
              content={content}
              loading={loading}
              minimumReviewLength={minimumReviewLength}
              productType={productType}
              setAttachments={this.setAttachments}
              setContent={this.setContent}
              setPastedContentLength={this.setPastedContentLength}
              textareaRef={this.textareaRef}
              track={track}
              uploadState={uploadState}
            />
          </PageSection>
          <PageSection active={reviewContentActive} visible={hasStars}>
            <Submit
              acceptTermsMessageVisible={!acceptsTerms && triedSubmit && !acceptsTermsChanged}
              acceptTermsVisible={!consumerUserExists}
              acceptsTerms={acceptsTerms}
              consumerEmail={consumerEmail}
              consumerName={consumerName}
              consumerUserExists={consumerUserExists}
              editMode={editMode || existing}
              enabled={!loading}
              keyPrefix={productId}
              ip={ip}
              locale={locale}
              setAcceptsTerms={this.setAcceptsTerms}
              submit={this.submit}
              track={track}
              hasAttachemts={Object.values(this.state.attachments).length > 0}
            />
          </PageSection>
        </ExpandableContent>
      </React.Fragment>
    );
  }

  editReview(e) {
    e.preventDefault();
    const scroller = this.scrollToBeginning();
    scroller.on('end', () => {
      this.setState({
        pristine: false,
      });
    });
  }

  areAttributeRatingsValid() {
    const { attributeRatings } = this.state;
    const { productType } = this.props;

    return (
      !attributeRatings.length ||
      attributeRatings.every((attribute) => attribute.rating > 0 || attribute.notApplicable) ||
      productType !== 'product'
    );
  }

  isReviewValid() {
    const { content, stars } = this.state;
    const { acceptsTerms, consumerUserExists, minimumReviewLength } = this.props;

    const reviewReady =
      stars > 0 && this.areAttributeRatingsValid() && content.length >= minimumReviewLength;

    const consumerHasAcceptedTerms = consumerUserExists || acceptsTerms;

    return reviewReady && consumerHasAcceptedTerms;
  }

  onUploadProgress(attachmentName) {
    return (progressEvent) => {
      this.setUploadState(attachmentName, progressEvent);
    };
  }

  scrollToBeginning(duration = 800) {
    const scroller = scrollToComponent(this.mainRatingRef, {
      offset: -60,
      align: 'top',
      duration,
      ease: 'inOutBack',
    });
    return scroller;
  }

  scrollToReviewContent() {
    if (!this.state.scrolledToReviewContent) {
      if (!this.props.desktop) {
        scrollToComponent(this.reviewContentRef, {
          offset: 0,
          align: 'top',
          duration: 800,
          ease: 'inOutBack',
        }).on('end', () => this.textareaRef.current.focus());
      }
      this.setState({ scrolledToReviewContent: true });
    }
  }

  checkScrollToReviewContent() {
    const { attributeRatings } = this.state;

    const finished = attributeRatings.every(
      (attributeRating) => attributeRating.notApplicable || attributeRating.rating > 0
    );

    if (finished) {
      this.scrollToReviewContent();
    }
  }

  setAcceptsTerms(acceptsTerms) {
    this.props.setAcceptsTerms(acceptsTerms);
    this.setState({ acceptsTermsChanged: true });
  }

  setAttachments(attachments) {
    if (this.props.consumerUserExists) {
      this.uploadAttachments(attachments);
    }
    this.setState({ attachments });
  }

  setAttributeNotApplicable(attributeId, notApplicable) {
    const attributeRatings = this.state.attributeRatings.slice();
    attributeRatings.forEach((attributeRating) => {
      if (attributeRating.attributeId === attributeId) {
        attributeRating.rating = 0;
        attributeRating.notApplicable = notApplicable;
      }
    });
    this.setState({ attributeRatings }, this.checkScrollToReviewContent);

    this.props.track('Button Clicked', {
      action: notApplicable
        ? 'Mark attribute as not applicable'
        : 'Unmark attribute as not applicable',
      context: 'Product Reviews',
      name: attributeId,
      page: 'Product Reviews Evaluate',
      productSku: this.props.sku,
    });
  }

  setAttributeRating(attributeId, rating) {
    const attributeRatings = this.state.attributeRatings.slice();
    attributeRatings.forEach((attributeRating) => {
      if (attributeRating.attributeId === attributeId) {
        attributeRating.rating = rating;
      }
    });
    this.setState({ attributeRatings }, this.checkScrollToReviewContent);

    //TODO: Still track as star button when it's a scale?
    this.props.track('Starbutton Clicked', {
      context: 'Product Reviews',
      name: attributeId,
      page: 'Product Reviews Evaluate',
      productSku: this.props.sku,
      stars: rating,
    });
  }

  setContent(content) {
    if (!this.state.contentChanged) {
      this.props.track('Evaluate.ReviewBodyInteraction', {
        context: 'Product Reviews',
        page: 'Product Reviews Evaluate',
      });
    }

    this.setState({ content, contentChanged: true });
  }

  setPastedContentLength(pastedContentLength) {
    if (pastedContentLength > (this.state.pastedContentLength || 0)) {
      this.setState({ pastedContentLength });
    }
  }

  setRating(stars) {
    if (this.state.loading) return;
    if (this.state.pristine) {
      this.setState({
        pristine: false,
        stars,
      });

      const scroller = this.scrollToBeginning();
      scroller.on('end', () => {
        if (this.props.productType !== 'product' || this.state.attributeRatings.length === 0) {
          this.textareaRef.current.focus();
        }
      });
    } else {
      this.setState({
        stars,
      });
    }
    this.props.track('ProductReviewsEvaluateStarsSelected', { stars });
  }

  setUploadState(attachmentName, progressEvent) {
    const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
    const { uploadState } = this.state;
    uploadState[attachmentName] = progress;
    this.setState({ uploadState });
  }

  async submit(simplicity) {
    this.setState({
      acceptsTermsChanged: false,
      contentChanged: false,
      triedSubmit: true,
      uploadState: {},
    });
    if (this.isReviewValid()) {
      this.setState({ loading: true });
      this.props.track('ProductReviewsEvaluateSubmit', {});

      const accessToken = await this.props.ensureUserExists();

      const attachments = await this.waitForAttachments(accessToken);

      const { attributeRatings, content, pastedContentLength, stars } = this.state;
      const review = {
        attachments: attachments.map(({ id }) => ({ attachmentId: id })),
        attributeRatings,
        content,
        pastedContentLength,
        stars,
      };
      const reviewExists = this.props.existing;

      const response = await this.props.submit(review, simplicity, accessToken);
      if (response) {
        this.setState({
          attachments: attachments.reduce(
            (output, attachment) => ({
              ...output,
              [attachment.id]: attachment,
            }),
            {}
          ),
          error: null,
          loading: false,
          triedSubmit: false,
          pristine: true,
        });
        this.trackPostedProductReview(
          response.successful[0].response.productReviewId,
          reviewExists,
          attachments
        );
        this.scrollToBeginning();
      }
    }
  }

  trackPostedProductReview(reviewId, reviewExists, attachments) {
    const attachmentsCount = attachments?.length || 0;
    const photoAttachmentsCount =
      attachments?.filter((attachment) => attachment.type?.startsWith('image'))?.length || 0;
    const videoAttachmentsCount =
      attachments?.filter((attachment) => attachment.type?.startsWith('video'))?.length || 0;

    const data = {
      contentLength: this.state.content.length,
      reviewId: reviewId,
      sku: this.props.sku,
      stars: this.state.stars,
      source: this.props.source,
      attachmentsCount,
      photoAttachmentsCount,
      videoAttachmentsCount,
    };

    const { attributeRatings } = this.state;
    if (attributeRatings.length) {
      const filledAttributeCount = attributeRatings.filter(({ rating }) => rating > 0).length;
      data.attributeRatingsFilled =
        filledAttributeCount === 0
          ? 'No'
          : filledAttributeCount < attributeRatings.length
          ? 'Partially'
          : 'Full';
    }

    this.props.track(`ProductReview${reviewExists ? 'Updated' : 'Created'}`, data);
  }

  uploadAttachments(attachments, accessToken) {
    const promises = this.promisesRef.current;
    for (const attachment of Object.values(attachments)) {
      if (attachment.file && typeof promises[attachment.name] === "undefined") {
        promises[attachment.name] = new Promise((resolve) => {
          this.props.postAttachment(accessToken).then(({ attachmentId, url }) => {
            this.setUploadState(attachment.name, 0);
            api
              .putAttachment(
                url,
                attachment.file,
                attachment.type,
                this.onUploadProgress(attachment.name)
              )
              .then(() => {
                resolve({
                  ...attachment,
                  id: attachmentId,
                });
              });
          });
        });
      }
    }
  }

  waitForAttachments(accessToken) {
    const promises = Object.values(this.state.attachments).map((attachment) => {
      if (attachment.id) {
        return Promise.resolve(attachment);
      } else {
        if (!this.promisesRef.current[attachment.name]) {
          this.uploadAttachments([attachment], accessToken);
        }
        return this.promisesRef.current[attachment.name];
      }
    });
    return Promise.all(promises);
  }
}

export default Review;
