import styled from '@emotion/styled';
import { Text, withTranslations } from 'ilenia';
import React from 'react';
import AcceptTerms from './AcceptTerms';
import Spinner from './Spinner';

const StylesSpinnedWrapper = styled.div`
  margin: 0.4rem 10px 0 0;
`;

class Submit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitEvent: null,
    };

    this.submit = this.submit.bind(this);
  }

  render() {
    return (
      <div className="product-review-submit">
        {this.props.acceptTermsVisible && (
          <AcceptTerms
            checked={this.props.acceptsTerms}
            keyPrefix={this.props.keyPrefix}
            messageVisible={this.props.acceptTermsMessageVisible}
            onChange={(e) => this.props.setAcceptsTerms(e.target.checked)}
          />
        )}
        <div className="submit-button-wrapper">
          {!this.state.submitEvent && (
            <form onSubmit={this.submit}>
              <input type="hidden" name="simplicity" />
              <button
                className={`product-review-submit-button ${this.props.enabled ? '' : 'disabled'}`}
                type="submit"
              >
                {this.props.enabled ? (
                  <Text
                    id={
                      this.props.editMode
                        ? 'productreviews-evaluatepage.edit.postupdatedreview'
                        : 'productreviews-evaluatepage.submit.button'
                    }
                  />
                ) : (
                  <>
                    <StylesSpinnedWrapper>
                      <Spinner color="#fff" size={20} />
                    </StylesSpinnedWrapper>
                    { this.props.hasAttachemts
                      ? <Text id="productreviews-evaluatepage.submit.buttonloading"/>
                      : <Text id="productreviews-evaluatepage.submit.buttonloadingnoattachments"/>
                    }
                  </>
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }

  async submit(e) {
    e.preventDefault();

    if (this.props.enabled) {
      this.setState({ submitEvent: null });
      const { children = [] } = e.currentTarget || {};
      const node = Array.from(children).find((child) => child.name === 'simplicity');
      const simplicity = node ? node.value : null;
      await this.props.submit(simplicity);
    }
  }
}

export default withTranslations(Submit);
