import React from 'react';
import { Text } from 'ilenia';

const SectionHeading = ({ translationId }) => (
  <h3 className="section-heading">
    <Text id={translationId} />
  </h3>
);

export default SectionHeading;
