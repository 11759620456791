import React from 'react';
import { Text } from 'ilenia';
import styled from '@emotion/styled';

import AttributeStarSelector from './AttributeStarSelector';
import AttributeScaleSelector from './AttributeScaleSelector';
import { AttributeTypes } from '../helper';

const StyledNotApplicableButton = styled.button`
  background: none;
  border: 0;
  color: #9a9aad;
  font-size: 0.875rem;
  padding: 0;
  text-align: right;
  text-decoration: underline;
  width: ${(props) => (props.isScaleAttribute ? 'fit-content' : '100%')};

  :hover {
    cursor: pointer;
  }

  @media screen and (min-width: 48rem) {
    width: fit-content;
  }
`;

const StyledAttributeRowWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isScaleAttribute ? 'column' : 'row')};
  align-items: ${(props) => (props.isScaleAttribute ? 'flex-start' : 'flex-end')};
`;

const Attribute = ({
  attributeName,
  attributeType,
  attributeOptions,
  keyPrefix,
  notApplicable,
  rating,
  setNotApplicable,
  setRating,
  style,
  loading,
}) => (
  <div className={`product-attributes-row ${notApplicable ? 'not-applicable' : ''}`} style={style}>
    <h4 className="attribute-name">{attributeName}</h4>
    <StyledAttributeRowWrapper isScaleAttribute={attributeType === AttributeTypes.Scale}>
      {attributeType === AttributeTypes.Default && (
        <AttributeStarSelector
          attribute={keyPrefix}
          setRating={setRating}
          disabled={notApplicable || loading}
          starsSelected={rating || 0}
        />
      )}
      {attributeType === AttributeTypes.Scale && (
        <AttributeScaleSelector
          attribute={keyPrefix}
          setRating={setRating}
          disabled={notApplicable || loading}
          optionSelected={rating || 0}
          options={attributeOptions}
        />
      )}
      <StyledNotApplicableButton
        isScaleAttribute={attributeType === AttributeTypes.Scale}
        onClick={() => setNotApplicable(!notApplicable)}
        disabled={loading}
      >
        <span>
          <Text
            id={
              notApplicable
                ? 'productreviews-evaluatepage.productattributes.notApplicableClicked'
                : 'productreviews-evaluatepage.productattributes.notApplicable'
            }
          />
        </span>
      </StyledNotApplicableButton>
    </StyledAttributeRowWrapper>
  </div>
);

export default Attribute;
