import React from 'react';
import posed from 'react-pose';
import TrustpilotHeader from './TrustpilotHeader';
import { Text } from 'ilenia';

const Container = posed.div({
  enter: { staggerChildren: 50 },
});

const P = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 },
});

export default ({ translationId }) => (
  <Container className="error-page">
    <P>
      <TrustpilotHeader />
    </P>
    <P className="error-message">
      <Text id={translationId} />
    </P>
  </Container>
);
