import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import TrustpilotHeader from './TrustpilotHeader';

const loadingShimmer = (props) => css`
  -webkit-animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: ${props.darker ? '#d4d4d4' : '#f2f2f2'};
  background-image: ${props.darker
    ? 'linear-gradient(to right, #d4d4d4 0%, #a9a9a9 20%, #d4d4d4 40%, #d4d4d4 100%)'
    : 'linear-gradient(to right, #f2f2f2 0%, #e0e0e0 20%, #f2f2f2 40%, #f2f2f2 100%)'};
  background-repeat: no-repeat;
  background-size: 800px 160px;
  opacity: ${props.darker ? '0.4' : '1'};
  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

const StyledProductImage = styled.div`
  width: 4.5625rem;
  height: 4.5625rem;
  margin: 1.3125rem 1.0625rem;

  @media (min-width: 48rem) {
    width: 7.5rem;
    height: 7.5rem;
    margin: 2rem;
  }

  ${loadingShimmer};
`;

const StyledProductInformation = styled.div`
  margin-top: 2rem;

  @media (min-width: 48rem) {
    width: 24rem;
    height: 1rem;
    margin-top: 3.2rem;
  }
`;

const StyledProductMessage = styled.div`
  width: 12rem;
  height: 0.75rem;
  margin-bottom: 0.5rem;

  @media (min-width: 48rem) {
    width: 24rem;
    height: 1rem;
    margin-bottom: 1rem;
  }

  ${loadingShimmer};
`;

const StyledWhiteCard = styled.div`
  box-shadow: 0 0.3125rem 0.1875rem -0.1875rem rgba(3, 3, 3, 0.06);
  background-color: white;
  max-width: 48rem;
  margin: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (min-width: 48rem) {
    margin: 1.5rem auto;
  }
`;

const StyledLoadingPage = styled.div`
  width: 100%;
`;

const LoadingPage = () => (
  <StyledLoadingPage>
    <TrustpilotHeader />
    <StyledWhiteCard>
      <StyledProductImage />
      <StyledProductInformation>
        <StyledProductMessage />
        <StyledProductMessage />
      </StyledProductInformation>
    </StyledWhiteCard>
  </StyledLoadingPage>
);

const EditStartupPage = () => <LoadingPage />;

export default EditStartupPage;
