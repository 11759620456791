import React from 'react';
import posed from 'react-pose';
import { Text, withTranslations } from 'ilenia';
import { findBestProductImage } from '../helper';

import FlexColumns from '../layout/FlexColumns';
import StarSelector from './StarSelector';

const transition = {
  duration: 400,
  ease: [0.08, 0.69, 0.2, 0.99],
};

const P = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0, transition },
});

const Image = posed.img({
  init: {
    position: 'static',
    transition,
    flip: true,
    'object-fit': 'cover',
  },
});

const Rating = ({
  editReview,
  fallbackImageUrl,
  name,
  productImages,
  productType,
  setRating,
  showThankYouFeedback,
  stars,
  translations,
  loading,
}) => {
  stars = stars || 0;
  const productImage = findBestProductImage(productImages);
  const imageUrl = productImage ? productImage.url : fallbackImageUrl;
  const ratingHelperTranslationId = `productreviews-evaluatepage.starselectionhelper${
    productType === 'product' ? '' : 'experience'
  }`;
  const thankYouFeedbackTranslationId = `productreviews-evaluatepage.rating.thankYouFeedback${
    productType === 'product' ? '' : 'Experience'
  }`;

  return (
    <FlexColumns className="product-overall-rating">
      <P>
        <FlexColumns
          className={`${stars > 0 && imageUrl ? 'flex-left' : 'flex-centered'} white-card`}
        >
          <FlexColumns className="flex-centered">
            <Image
              src={imageUrl}
              className={stars > 0 ? 'medium-product-image' : 'large-product-image'}
            />
          </FlexColumns>
          {!showThankYouFeedback ? (
            <FlexColumns
              className={`${stars > 0 && imageUrl ? '' : 'flex-centered'} overall-rating-box`}
            >
              <h1 className={stars > 0 ? 'medium-product-title' : 'large-product-title'}>{name}</h1>
              <StarSelector
                name="review-rating"
                onChange={setRating}
                stars={stars}
                size={stars > 0 ? 'medium' : 'large'}
                disabled={loading}
              />
              {stars === 0 && (
                <p className="rating-helper">
                  <Text id={ratingHelperTranslationId} />
                </p>
              )}
            </FlexColumns>
          ) : (
            <FlexColumns className="overall-rating-box">
              <h1 className="medium-product-title with-less-margin">{name}</h1>
              <p className="thank-you-feedback">
                <Text id={thankYouFeedbackTranslationId} />
              </p>
              <button className="edit-review" onClick={editReview}>
                <img
                  alt="" // we don't need alt text here as the image is decorative and not informative (i.e. you wouldn't miss any info if it was removed from the page)
                  src="https://productreviews-evaluatepage.trustpilot.com/assets/Edit_icon.svg"
                  title={translations['productreviews-evaluatepage.rating.editReview']}
                />
                <Text id="productreviews-evaluatepage.rating.editReview" />
              </button>
            </FlexColumns>
          )}
        </FlexColumns>
      </P>
    </FlexColumns>
  );
};

export default withTranslations(Rating);
