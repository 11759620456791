import React from 'react';
import StarSelector from './StarSelector';

const AttributeStarSelector = ({
  attribute,
  setRating,
  disabled = false,
  starsSelected,
}) => (
  <StarSelector name={attribute} onChange={setRating} stars={starsSelected} disabled={disabled} size="small" />
);

export default AttributeStarSelector;
