import React from 'react';
import styled from '@emotion/styled';

const StyledInputRows = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInputRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  height: 24px;
  width: 24px;
  :hover {
    cursor: ${(props) => (props.disabled ? 'arrow' : 'pointer')};
  }
`;

const StyledLabel = styled.label`
  padding-left: 8px;
  color: #6c6c85;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  opacity: ${(props) => (props.disabled ? 0.15 : 1)};
  :hover {
    cursor: ${(props) => (props.disabled ? 'arrow' : 'pointer')};
  }
`;

const AttributeScaleSelector = ({
  attribute,
  setRating,
  disabled = false,
  optionSelected,
  options,
}) => {
  return (
    <StyledInputRows>
      {options.map((option, index) => (
        // We have a static set of values here (as in we're not doing any changes to the array) so it's okay to use the index as a key
        // eslint-disable-next-line react/no-array-index-key
        <StyledInputRow key={index}>
          <StyledInput
            id={option}
            type="radio"
            name={attribute}
            value={option}
            disabled={disabled}
            onChange={
              disabled
                ? null
                : () => {
                    setRating(index + 1);
                  }
            }
            checked={optionSelected === index + 1}
          />
          <StyledLabel htmlFor={option} disabled={disabled}>
            {option}
          </StyledLabel>
        </StyledInputRow>
      ))}
    </StyledInputRows>
  );
};

export default AttributeScaleSelector;
