import styled from '@emotion/styled';
import { HtmlText, Text, useTranslations } from 'ilenia';
import React, { createRef, useRef, useState } from 'react';
import Lightbox from 'lightbox-react';
import loadImage from 'blueimp-load-image';

import Spinner from './Spinner';
import { extractFrame, getMediaType } from '../helper';
import VideoTmpThumbnail from '../assets/video_tmp_thumbnail.png';
import ShakaPlayer from './ShakaPlayer';

const maxPhotoCount = 3;
const videoSizeLimitMB = 100;
const videoSizeLimit = videoSizeLimitMB * 1000 * 1000;
const imageSizeLimitMB = 10;
const imageSizeLimit = imageSizeLimitMB * 1000 * 1000;

const StyledAddPhotoWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const StyledAddPhotoButton = styled.label`
  align-items: center;
  background: #fff;
  border: 0.09375rem solid #e3e4e6;
  border-radius: 0.125rem;
  color: #363443;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.size === 'large' ? '100%' : '8rem')};
  height: 5rem;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0;

  @media screen and (min-width: 48rem) {
    width: 5rem;
    padding: 0 0.75rem;
  }

  @media (pointer: fine) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledAddPhotoOrVideoButtonImage = styled.img`
  display: block;
  margin: 0.75rem 0 0.5rem;
  width: 1.5rem;
`;

const StyledAddPhotoButtonLabel = styled.div`
  color: #6f6f87;
  font-size: 0.875rem;
  line-height: 1rem;
  flex-basis: 100%;
  text-align: center;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const StyledFileSizeLimitHit = styled.div`
  color: #eb1700;
  font-size: 0.875rem;
  margin: 0.625rem 0 0 0;
`;

const StyledTitle = styled.p`
  color: #454554;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0 0 0.75rem 0;

  & > .optional-message {
    color: #9a9aad;
    font-weight: 400;
  }

  @media screen and (min-width: 48rem) {
    font-size: 1rem;
  }
`;

const StyledPhotoSection = styled.div`
  margin: 0 0 1.5rem;
  padding: 0 1rem;

  @media screen and (min-width: 48rem) {
    padding: 0 2rem;
  }
`;

const StyledRemoveButton = styled.div`
  align-items: center;
  background: rgb(54, 52, 67, 0.85);
  border: 0.0625rem solid rgb(255, 255, 255);
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 1.25rem;
  justify-content: center;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  width: 1.25rem;
`;

const StyledThumbnail = styled.img`
  border: none;
  border-radius: 0.125rem;
  height: 5rem;
  object-fit: cover;
  width: 5rem;

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledThumbnailWrapper = styled.div`
  position: relative;
  margin-right: 1.25rem;
`;

const StyledPlayIcon = styled.img`
  height: 3rem;
  object-fit: cover;
  width: 3rem;
  position: absolute;
  top: 1rem;
  left: 1rem;

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledProgressBar = styled.div`
  width: ${(props) => props.progress}%;
  position: absolute;
  height: 0.3rem;
  bottom: 0.2rem;
  left: 0;
  background-color: #00b67a;
  border-radius: 0 0 0 0.125rem;
`;

const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhotoSection = ({
  attachments = {},
  loading,
  setAttachments,
  uploadState,
  track,
}) => {
  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [fileSizeLimitHit, setFileSizeLimitHit] = useState(null);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [translations, ] = useTranslations();

  const videoRefs = useRef(Array.from({ length: maxPhotoCount }, () => createRef()));

  const onChange = (changeEvent) => {
    // we do not accept multiple files, safe to assume there is only one
    const { type, size } = changeEvent.target.files[0];
    const mediaType = getMediaType(type);

    let fileSizeLimit = null;
    if (mediaType === 'video' && size > videoSizeLimit) {
      fileSizeLimit = videoSizeLimitMB;
    }
    if (mediaType === 'image' && size > imageSizeLimit) {
      fileSizeLimit = imageSizeLimitMB;
    }

    track('Button Clicked', {
      action: 'Select photo',
      context: 'Product Reviews',
      page: 'Product Reviews Evaluate',
      mediaType,
      hitSizeLimit: !!fileSizeLimit,
      attemptedSize: size,
    });

    setFileSizeLimitHit(fileSizeLimit);
    if (fileSizeLimit) {
      // do not handle file if larger than limit
      return;
    }

    setLoadingPhotos(true);

    const promises = Array.from(changeEvent.target.files).map(
      (file) =>
        new Promise(async (resolve) => {
          const frame = file.type?.startsWith('video') ? VideoTmpThumbnail : file;
          loadImage(
            frame,
            (image) => {
              resolve({
                file,
                name: file.name,
                src: URL.createObjectURL(file),
                thumbnailSrc: image.toDataURL(),
                hasThumbnail: false,
                type: file.type,
              });
            },
            { orientation: true, canvas: true }
          );
        })
    );

    Promise.all(promises).then((results) => {
      setAttachments(
        results.reduce(
          (output, file) => ({
            ...output,
            [file.name]: file,
          }),
          attachments
        )
      );

      setLoadingPhotos(false);
    });
  };

  // Use attachmentsRef to have access to the latest state of attachments from extractFrame promise
  const attachmentsRef = useRef(attachments);
  React.useEffect(() => {
    attachmentsRef.current = attachments;
  }, [attachments]);

  React.useEffect(() => {
    const promises = Object.values(attachments)
      .filter(
        ({ type, file, hasThumbnail }) => type && type.startsWith('video') && file && !hasThumbnail
      )
      .map(
        (attachment) =>
          new Promise(async (resolve) => {
            const frame = await extractFrame(attachment.file);
            loadImage(
              frame,
              (image) => {
                resolve({
                  ...attachment,
                  hasThumbnail: true,
                  thumbnailSrc: image.toDataURL(),
                });
              },
              { orientation: true, canvas: true }
            );
          })
      );
    Promise.all(promises).then((results) => {
      setAttachments(
        results.reduce(
          (output, file) => ({
            ...output,
            ...(attachmentsRef.current[file.name] && { [file.name]: file }),
          }),
          attachmentsRef.current
        )
      );
    });
  }, [attachments, setAttachments]);

  const removePhoto = (name) => {
    track('Button Clicked', {
      action: 'Remove photo',
      context: 'Product Reviews',
      page: 'Product Reviews Evaluate',
    });

    const filteredAttachments = { ...attachments };
    delete filteredAttachments[name];
    setAttachments(filteredAttachments);
  };

  const trackOpenPhotoSelector = () => {
    track('Button Clicked', {
      action: 'Open photo selector',
      context: 'Product Reviews',
      page: 'Product Reviews Evaluate',
    });
  };

  const fileArray = Object.values(attachments).map((attachment, index) => {
    if (attachment.type?.startsWith('video')) {
      return {
        ...attachment,
        src: (
          <StyledVideo key={attachment.name}>
            <ShakaPlayer
              manifestUri={attachment.src}
              file={attachment.file}
              posterUri={attachment.thumbnailSrc}
              videoRef={videoRefs.current[index]}
              type={attachment.type}
            />
          </StyledVideo>
        ),
      };
    } else {
      return attachment;
    }
  });

  let lightbox;
  if (selectedPhotoIndex !== null) {
    const previous = selectedPhotoIndex - 1;
    const next = selectedPhotoIndex + 1;
    lightbox = (
      <Lightbox
        enableZoom={false}
        mainSrc={fileArray[selectedPhotoIndex].src}
        nextSrc={next !== selectedPhotoIndex ? fileArray[next]?.src : null}
        onCloseRequest={() => setSelectedPhotoIndex(null)}
        onMoveNextRequest={() => setSelectedPhotoIndex(next)}
        onMovePrevRequest={() => setSelectedPhotoIndex(previous)}
        prevSrc={previous !== selectedPhotoIndex ? fileArray[previous]?.src : null}
        onAfterOpen={() =>
          videoRefs.current[selectedPhotoIndex]?.current?.addEventListener('loadeddata', () =>
            videoRefs.current[selectedPhotoIndex]?.current?.play()
          )
        }
      />
    );
  }

  const selectedPhotoIndexRef = useRef(null); // stores index of currently playing video
  React.useEffect(() => {
    if (selectedPhotoIndex !== selectedPhotoIndexRef) {
      const prevMedia = videoRefs.current[selectedPhotoIndexRef.current]?.current;
      if (prevMedia) {
        prevMedia.pause();
      }
      selectedPhotoIndexRef.current = selectedPhotoIndex;
      const newMedia = videoRefs.current[selectedPhotoIndex]?.current;
      if (newMedia) {
        newMedia.play();
      }
    }
  }, [selectedPhotoIndex]);

  return (
    <StyledPhotoSection>
      <StyledTitle>
        <HtmlText
          id='productreviews-evaluatepage.photoandvideo.title'
          interpolations={{
            HTML1: '<span key="productreviews-evaluatepage.photoandvideo.title" class="optional-message">',
            HTML2: '</span>',
          }}
        />
      </StyledTitle>
      <StyledAddPhotoWrapper>
        {fileArray.map((file, index) => (
          <StyledThumbnailWrapper key={file.name}>
            {!loading && (
              <StyledRemoveButton onClick={() => removePhoto(file.name)}>
                <img
                  alt={translations['productreviews-evaluatepage.photoandvideo.removeAltTag']}
                  src="https://productreviews-evaluatepage.trustpilot.com/assets/Photo_remove_icon.svg"
                />
              </StyledRemoveButton>
            )}
            <StyledThumbnail
              focused={selectedPhotoIndex === index}
              loading={loading.toString()}
              onClick={() => setSelectedPhotoIndex(index)}
              src={file.thumbnailSrc || file.src}
            />
            {file.type?.startsWith('video') && (
              <StyledPlayIcon
                src="/assets/play_icon.svg"
                onClick={() => setSelectedPhotoIndex(index)}
              />
            )}
            {uploadState[file.name] && uploadState[file.name] < 100 ? (
              <StyledProgressBar
                progress={uploadState[file.name]}
                onClick={() => setSelectedPhotoIndex(index)}
              />
            ) : null}
          </StyledThumbnailWrapper>
        ))}
        {fileArray.length < maxPhotoCount && (
          <StyledAddPhotoButton
            size={fileArray.length === 0 ? 'large' : 'medium'}
            onClick={trackOpenPhotoSelector}
          >
            {loadingPhotos ? (
              <Spinner size={20} />
            ) : (
              <React.Fragment>
                <StyledAddPhotoOrVideoButtonImage
                  src="/assets/Photo_icon.svg"
                  title="Add photos"
                />
                <StyledAddPhotoButtonLabel>
                  {maxPhotoCount - fileArray.length > 1 ? (
                    <Text
                      id="productreviews-evaluatepage.photoandvideo.add"
                      interpolations={{ REMAININGPHOTOS: maxPhotoCount - fileArray.length }}
                      tag={{
                        start: '[',
                        end: ']',
                      }}
                    />
                  ) : (
                    <Text id="productreviews-evaluatepage.photoandvideo.add"/>
                  )}
                </StyledAddPhotoButtonLabel>
                <StyledFileInput
                  accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                  disabled={loading}
                  onChange={onChange}
                  type="file"
                />
              </React.Fragment>
            )}
          </StyledAddPhotoButton>
        )}
      </StyledAddPhotoWrapper>
      {selectedPhotoIndex !== null && lightbox}
      {fileSizeLimitHit && (
        <StyledFileSizeLimitHit>
          <Text
            id="productreviews-evaluatepage.photoandvideo.filesizelimit"
            interpolations={{
              FILE_SIZE_LIMIT: `${fileSizeLimitHit}MB`,
            }}
          />
        </StyledFileSizeLimitHit>
      )}
    </StyledPhotoSection>
  );
};
