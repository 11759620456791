import React from 'react';
import styled from '@emotion/styled';

const StyledConversationComment = styled.div`
  margin-bottom: 1rem;
`;

const StyledAuthorDate = styled.div`
  padding-right: 2rem;
  margin-left: ${(props) => (props.isBusinessComment ? '0' : 'auto')};
  margin-bottom: 0.5rem;
  width: 70%;
`;

const StyledAuthor = styled.span`
  color: #454554;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.01875rem;
  line-height: 1.25rem;
`;

const StyledDate = styled.span`
  color: #9a9aad;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding-left: 0.5rem;
`;

const StyledContent = styled.div`
  color: #010130;
  background-color: ${(props) => (props.isBusinessComment ? '#e7f4fe' : '#f2f2f2')};
  border-radius: 0.3125rem;
  margin-left: ${(props) => (props.isBusinessComment ? '0' : 'auto')};
  font-size: 0.875rem;
  padding: 1rem;
  width: 70%;
  white-space: pre-wrap;
`;

const ConversationComment = ({ content, createdAt, isBusinessComment, moment, name }) => (
  <StyledConversationComment>
    <StyledAuthorDate isBusinessComment={isBusinessComment}>
      <StyledAuthor>{name}</StyledAuthor>
      <StyledDate>{moment(createdAt, 'll', true)}</StyledDate>
    </StyledAuthorDate>
    <StyledContent isBusinessComment={isBusinessComment}>{content}</StyledContent>
  </StyledConversationComment>
);

export default ConversationComment;
