import React from 'react';

const TrustpilotLogo = ({ href = 'https://www.trustpilot.com' }) => (
  <a href={href}>
    <img
      id="tp-logo-img"
      src="https://cdn.trustpilot.net/brand-assets/1.8.0/logo-white.svg"
      className="logo__header"
      title="Trustpilot"
      alt="Trustpilot logo"
    />
  </a>
);

export default TrustpilotLogo;
