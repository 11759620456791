import React from 'react';
import { Text, withTranslations } from 'ilenia';
import styled from '@emotion/styled';

import ConversationComment from './ConversationComment';
import Spinner from './Spinner';

const StyledConversation = styled.div`
  margin-top: 1.375rem;

  @media screen and (min-width: 48rem) {
    width: 48rem;
  }
`;

const StyledConversationHeader = styled.p`
  color: #454554;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
`;

const StyledConversationComments = styled.div`
  background-color: #ffffff;
  border-radius: 0.125rem 0.125rem 0 0;
  padding: 1.5rem;
`;

const StylesSpinnedWrapper = styled.div`
  padding: 8px;
  text-align: center;
`;

const StyledReplyButton = styled.button`
  background-color: #00b67a;
  border: none;
  border-radius: 0.125rem;
  color: #ffffff;
  letter-spacing: 0.01875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  width: 100%;
  height: 2.75rem;

  @media screen and (min-width: 48rem) {
    width: 6.25rem;
  }
`;

const StyledSendForm = styled.div`
  background-color: #f2f2f2;
  border-radius: 0.25rem;
  padding: 0.75rem;
`;

const StyledSendTextArea = styled.textarea`
  background-color: #f2f2f2;
  border: none;
  font-size: 0.875rem;
  letter-spacing: 0.01875rem;
  line-height: 1.25rem;
  outline: none;
  box-shadow: none;
  resize: none;
  overflow: auto;
  width: 100%;
  min-height: 4.5rem;
`;

const StyledSendButton = styled.button`
  display: block;
  background-color: #00b67a;
  border: none;
  border-radius: 0.125rem;
  color: #ffffff;
  letter-spacing: 0.01875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  margin-left: auto;
  width: 5.5rem;
  height: 2.75rem;

  &:disabled {
    cursor: default;
    background: rgba(0, 182, 122, 0.5);
  }
`;

class Conversation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.submit = this.submit.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  async updateContent(e) {
    this.setState({ content: e.target.value });
  }

  async submit(e) {
    e.preventDefault();

    if (this.state.content.length < 1) {
      return;
    }

    await this.props.submit(this.state.content);

    this.setState({ content: '', expanded: false });
  }

  render() {
    const {
      businessName,
      conversation,
      conversationPostInProgress,
      moment,
      translations,
    } = this.props;

    const { content, expanded } = this.state;

    return (
      <StyledConversation>
        <StyledConversationHeader>
          <Text
            id="productreviews-evaluatepage.conversation.headerconversationwith"
            interpolations={{ BUSINESSUNIT: businessName }}
            tag={{
              start: '[',
              end: ']',
            }}
          />
        </StyledConversationHeader>
        <StyledConversationComments>
          {conversation.comments.map((comment) => {
            const isBusinessComment = ['businessUser', 'integration'].includes(comment.author.type);

            return (
              <ConversationComment
                content={comment.content}
                createdAt={comment.createdAt}
                isBusinessComment={isBusinessComment}
                key={comment.commentId}
                moment={moment}
                name={
                  isBusinessComment
                    ? businessName
                    : translations['productreviews-evaluatepage.conversation.you']
                }
              />
            );
          })}
          {conversationPostInProgress && (
            <StylesSpinnedWrapper>
              <Spinner />
            </StylesSpinnedWrapper>
          )}
          {!expanded && (
            <StyledReplyButton onClick={() => this.setState({ expanded: true })}>
              <Text id="productreviews-evaluatepage.conversation.buttonreply" />
            </StyledReplyButton>
          )}
          {expanded && !conversationPostInProgress && (
            <form onSubmit={this.submit}>
              <StyledSendForm>
                <StyledSendTextArea
                  onChange={this.updateContent}
                  placeholder={
                    translations['productreviews-evaluatepage.conversation.replyplaceholdertext']
                  }
                  value={content}
                />
                <StyledSendButton
                  disabled={!this.state.content || this.state.content.length < 1}
                  type="submit"
                >
                  <Text id="productreviews-evaluatepage.conversation.buttonsend" />
                </StyledSendButton>
              </StyledSendForm>
            </form>
          )}
        </StyledConversationComments>
      </StyledConversation>
    );
  }
}

export default withTranslations(Conversation);
