// Adapted from https://github.com/trustpilot/businessapp-designsystem/blob/master/src/components/Spinner/index.tsx

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const dash = keyframes`
  0% {
    stroke-dashoffset: 600;
  }
  100% {
      stroke-dashoffset: 0;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.svg`
  animation: ${rotate} 2s linear infinite;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const Line = styled.circle`
  stroke-width: 0.75rem;
  stroke-dasharray: 18.75rem;
  stroke-dashoffset: 37.5rem;
  stroke-linecap: round;
  stroke-milterlimit: 0.625rem;
  fill: none;
  animation: ${dash} 1.6s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite;
  stroke: ${(props) => props.color};
`;

const Spinner = ({ color = '#368CD7', size = 16 }) => (
  <StyledSpinner size={size} viewBox="0 0 150 150">
    <Line r="60" cx="75" cy="75" color={color} />
  </StyledSpinner>
);

export default Spinner;
