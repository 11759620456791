import React, { Component } from 'react';
import { HtmlText, Text } from 'ilenia';

import SectionHeading from './SectionHeading';
import { PhotoSection } from './PhotoSection';

class ReviewContent extends Component {
  constructor(props) {
    super(props);

    this.onPaste = this.onPaste.bind(this);
    this.trackFocusedReviewContent = this.trackFocusedReviewContent.bind(this);
  }

  render() {
    const {
      attachments,
      attachmentsEnabled,
      characterCountHighlighted,
      characterCountVisible,
      content,
      loading,
      minimumReviewLength,
      productType,
      setAttachments,
      setContent,
      textareaRef,
      track,
      uploadState,
    } = this.props;
    const charsRemaining = minimumReviewLength - (content ? content.length : 0);

    const placeholderTranslationId = `productreviews-evaluatepage.reviewArea.reviewtextarea${
      productType === 'product' ? '' : 'experience'
    }-placeholder`;
    const statusMessageTranslationId = `productreviews-evaluatepage.reviewArea.statusMessage${
      charsRemaining === minimumReviewLength ? 'NoText' : charsRemaining > 1 ? '' : 'Singular'
    }`;

    const placeholderInterpolations = {
      NUMBER: minimumReviewLength,
    };

    const statusMessageInterpolations = {
      HTML1: '<span key="number-characters" class="number-characters">',
      HTML2: '</span>',
      NUMBER: charsRemaining.toString(),
    };

    const shouldShowPhotoFeatures = attachmentsEnabled || Object.keys(attachments).length > 0;

    return (
      <div className="product-review-section">
        <div className={`product-review-content ${shouldShowPhotoFeatures ? 'with-photos' : ''}`}>
          <SectionHeading translationId="productreviews-evaluatepage.reviewArea.heading" />
          <p>
            <Text
              id={placeholderTranslationId}
              interpolations={placeholderInterpolations}
              tag={{ start: '{', end: '}' }}
            />
          </p>
          <textarea
            className="evaluate-textarea"
            onChange={(e) => setContent(e.target.value)}
            onFocus={() => this.trackFocusedReviewContent()}
            onPaste={this.onPaste}
            ref={textareaRef}
            value={content}
            disabled={loading}
          />
          <div
            className={`review-content-status-message ${
              characterCountHighlighted ? 'review-not-ready' : ''
            }`}
          >
            {(content || characterCountVisible) && charsRemaining > 0 && (
              <HtmlText
                id={statusMessageTranslationId}
                interpolations={statusMessageInterpolations}
                tag={{ start: '{', end: '}' }}
              />
            )}
          </div>
        </div>
        {shouldShowPhotoFeatures && (
          <PhotoSection
            attachments={attachments}
            loading={loading}
            setAttachments={setAttachments}
            track={track}
            uploadState={uploadState}
          />
        )}
      </div>
    );
  }

  onPaste(e) {
    if (typeof e.clipboardData !== 'undefined') {
      const initialValue = e.currentTarget.value || '';
      const pastedValue = e.clipboardData.getData('text/plain') || '';
      const pastedContentLength = pastedValue.length - initialValue.length;
      if (pastedContentLength > 0) {
        this.props.setPastedContentLength(pastedContentLength);
      }
    }
  }

  trackFocusedReviewContent() {
    this.props.track('Element Focused', {
      context: 'Product Reviews',
      name: 'Review content',
      page: 'Product Reviews Evaluate',
    });
  }
}

export default ReviewContent;
