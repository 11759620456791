import React from 'react';
import TrustpilotLogo from './TrustpilotLogo';

const TrustpilotHeader = () => (
  <div className="header" role="banner">
    <div className="header__container" data-header-container="">
      <div className="header__wrapper">
        <div className="logo__container">
          <TrustpilotLogo />
        </div>
      </div>
    </div>
  </div>
);

export default TrustpilotHeader;
