import styled from '@emotion/styled';
import { Text } from 'ilenia';
import React from 'react';
import posed from 'react-pose';
import Conversation from './Conversation';
import { ModerationHeader } from './ModerationHeader';
import Review from './Review';
import TestInvitationBanner from './TestInvitationBanner';
import TrustpilotHeader from './TrustpilotHeader';

const StyledFlexContainer = styled.div`
  @media screen and (min-width: 69.5rem) {
    display: ${(props) => (props.editMode ? 'flex' : 'block')};
  }
`;

const Container = posed.div({
  enter: { staggerChildren: 50 },
});

const P = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 },
});

const MainPage = ({
  acceptsTerms,
  attachmentsEnabled,
  businessName,
  consumerEmail,
  consumerName,
  consumerUserExists,
  conversation,
  conversationPostInProgress,
  desktop,
  editMode,
  ip,
  isMs,
  isTestInvitation,
  locale,
  minimumReviewLength,
  moment,
  postAttachment,
  postComment,
  productType,
  products,
  selectedSku,
  setAcceptsTerms,
  source,
  submit,
  track,
  ensureUserExists,
}) => {
  const hasModerationMessage =
    products[0].attachments &&
    products[0].attachments.some((attachment) => attachment.state === 'VerificationRejected');

  return (
    <Container>
      <P>
        <TrustpilotHeader />
        {isTestInvitation && <TestInvitationBanner />}
      </P>
      <P>
        <div
          className={`main-content ${
            editMode && hasModerationMessage ? 'edit-mode-with-moderation' : ''
          }`}
        >
          <div className="grey-card">
            {!editMode && (
              <React.Fragment>
                <h2>
                  <Text
                    id={
                      productType === 'product'
                        ? 'productreviews-evaluatepage.mainHeading'
                        : 'productreviews-evaluatepage.mainHeadingExperience'
                    }
                  />
                </h2>
                <p>
                  <Text
                    id="productreviews-evaluatepage.mainHeadingSub"
                    interpolations={{ BUSINESSUNIT: businessName }}
                  />
                </p>
              </React.Fragment>
            )}

            {editMode && hasModerationMessage && (
              <ModerationHeader
                businessName={businessName}
                consumerName={consumerName}
                productName={products[0].name}
                reviewDate={products[0].createdAt}
              />
            )}
          </div>

          <StyledFlexContainer editMode={editMode}>
            {products.map(({ isExistingProductReview, productId, ...product }) => (
              <section key={productId} className="review-section">
                <Review
                  {...product}
                  acceptsTerms={acceptsTerms}
                  attachmentsEnabled={attachmentsEnabled}
                  consumerEmail={consumerEmail}
                  consumerName={consumerName}
                  consumerUserExists={consumerUserExists}
                  editMode={editMode}
                  existing={isExistingProductReview}
                  desktop={desktop}
                  ip={ip}
                  isMs={isMs}
                  isProductSelected={selectedSku && product.sku === selectedSku}
                  locale={locale}
                  minimumReviewLength={minimumReviewLength}
                  postAttachment={postAttachment}
                  productId={productId}
                  productType={productType}
                  setAcceptsTerms={setAcceptsTerms}
                  source={source}
                  submit={(review, simplicity, accessToken) => submit({ productId, ...review }, simplicity, accessToken)}
                  track={track}
                  ensureUserExists={ensureUserExists}
                />
              </section>
            ))}
          </StyledFlexContainer>

          {editMode && conversation && (
            <Conversation
              businessName={businessName}
              conversation={conversation}
              conversationPostInProgress={conversationPostInProgress}
              moment={moment}
              submit={postComment}
            />
          )}
        </div>
      </P>
      <div className="invisible-box" />
    </Container>
  );
};

export default MainPage;
