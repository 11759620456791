import React from 'react';
import { Text } from 'ilenia';

import SectionHeading from './SectionHeading';
import Attribute from './Attribute';
import { AttributeTypes } from '../helper';

const Attributes = ({
  attributeRatings = [],
  attributeRatingsMessageVisible = false,
  isMs,
  keyPrefix = '',
  setNotApplicable,
  setRating,
  loading,
}) => {
  const filteredAttributeRatings = [];

  attributeRatings.forEach(
    (
      { attributeId, attributeName, attributeOptions, attributeType, notApplicable, rating },
      index
    ) => {
      const key = `${keyPrefix}${attributeId}`;
      const rowIndex = Math.floor(index / 2) + 1;
      const colIndex = (index % 2) + 1;

      if (
        index > 0 &&
        index % 2 === 1 && // Don't insert an empty div if the scale attribute already is in the first column
        attributeType === AttributeTypes.Scale &&
        attributeRatings[index - 1].attributeType === AttributeTypes.Default
      ) {
        filteredAttributeRatings.push(<div />);
      }

      filteredAttributeRatings.push(
        <Attribute
          {...{
            attributeId,
            attributeName,
            attributeOptions,
            attributeType,
            isMs,
            key,
            keyPrefix: key,
            notApplicable,
            rating,
            setNotApplicable: (value) => setNotApplicable(attributeId, value),
            setRating: (value) => setRating(attributeId, value),
            style: isMs ? { msGridRow: rowIndex, msGridColumn: colIndex } : {},
            loading: loading
          }}
        />
      );
    }
  );

  return (
    <section className="product-attributes">
      <SectionHeading translationId="productreviews-evaluatepage.productattributes.heading" />
      <div className="attributes-row-container">
        {filteredAttributeRatings}

        {attributeRatingsMessageVisible && (
          <p
            className="attributes-status-message"
            style={
              isMs
                ? {
                    msGridRow: Math.floor(attributeRatings.length / 2) + 2,
                    msGridColumn: 1,
                  }
                : {}
            }
          >
            <Text id="productreviews-evaluatepage.productattributes.statusMessage" />
          </p>
        )}
      </div>
      <p className="explanatory-text">
        <Text id="productreviews-evaluatepage.productattributes.helpotherslearnmore" />
      </p>
    </section>
  );
};

export default Attributes;
