import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import '@babel/polyfill';
import 'whatwg-fetch';
import config from './config.json';

Sentry.init({ dsn: config.SentryDsn, environment: config.Environment });

import { extractUrlInfo } from './helper';
import App from './components/App';

const { editMode, linkId, selectedSku } = extractUrlInfo(window.location.href);

const root = createRoot(document.getElementById('root'));
root.render(
  <App editMode={editMode} linkId={linkId} selectedSku={selectedSku} />
);

module.hot.accept();
