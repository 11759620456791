import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import TrustpilotHeader from './TrustpilotHeader';

const loadingShimmer = (props) => css`
  -webkit-animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: ${props.darker ? '#d4d4d4' : '#f2f2f2'};
  background-image: ${props.darker
    ? 'linear-gradient(to right, #d4d4d4 0%, #a9a9a9 20%, #d4d4d4 40%, #d4d4d4 100%)'
    : 'linear-gradient(to right, #f2f2f2 0%, #e0e0e0 20%, #f2f2f2 40%, #f2f2f2 100%)'};
  background-repeat: no-repeat;
  background-size: 800px 160px;
  opacity: ${props.darker ? '0.4' : '1'};
  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

const StyledHeading = styled.div`
  width: 20rem;
  margin: 1.5rem auto auto auto;

  @media (min-width: 48rem) {
    width: 48rem;
    margin-top: 2.75rem;
  }
`;

const StyledMainHeading = styled.div`
  width: 18rem;
  height: 0.75rem;

  @media (min-width: 48rem) {
    width: 30rem;
    height: 1rem;
  }

  ${loadingShimmer};
`;

const StyledSubHeading = styled.div`
  width: 15rem;
  height: 0.75rem;
  margin-top: 0.86rem;

  @media (min-width: 48rem) {
    width: 25rem;
    height: 1rem;
    margin-top: 1.2rem;
  }

  ${loadingShimmer};
`;

const StyledProductImage = styled.div`
  width: 7.1875rem;
  height: 7.1875rem;
  margin-top: 1.875rem;

  @media (min-width: 48rem) {
    width: 9.375rem;
    height: 9.375rem;
    margin-top: 2.5rem;
  }

  ${loadingShimmer};
`;

const StyledProductName = styled.div`
  width: 18rem;
  height: 0.75rem;
  margin-top: 2rem;

  @media (min-width: 48rem) {
    width: 24rem;
    height: 1rem;
    margin-top: 3.2rem;
  }

  ${loadingShimmer};
`;

const StyledProductStar = styled.div`
  width: 2.75rem;
  height: 2.75rem;
  margin-left: 0.25rem;

  ${loadingShimmer};
`;

const StyledProductStars = styled.div`
  margin-top: 1.45rem;
  margin-bottom: 4.35rem;
  display: flex;

  @media (min-width: 48rem) {
    margin-top: 1.5rem;
    margin-bottom: 3.2rem;
  }
`;

const StyledWhiteCard = styled.div`
  box-shadow: 0 0.3125rem 0.1875rem -0.1875rem rgba(3, 3, 3, 0.06);
  background-color: white;
  max-width: 48rem;
  margin: 2.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 48rem) {
    margin: 1.5rem auto;
  }
`;

const StyledLoadingPage = styled.div`
  width: 100%;
`;

const LoadingPage = () => (
  <StyledLoadingPage>
    <TrustpilotHeader />
    <StyledHeading>
      <StyledMainHeading darker />
      <StyledSubHeading darker />
    </StyledHeading>
    <StyledWhiteCard>
      <StyledProductImage />
      <StyledProductName />
      <StyledProductStars>
        <StyledProductStar />
        <StyledProductStar />
        <StyledProductStar />
        <StyledProductStar />
        <StyledProductStar />
      </StyledProductStars>
    </StyledWhiteCard>
  </StyledLoadingPage>
);

const EvaluateStartupPage = () => <LoadingPage />;

export default EvaluateStartupPage;
