import React, { useEffect, useCallback, useRef } from 'react';
import { Text, withTranslations } from 'ilenia';

const TestInvitationModal = ({ close, translations }) => {
  const modalRef = useRef();

  useEffect(()=>{
    document.addEventListener('keydown', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyUp);
    }
  },[onKeyUp]);

  const onOverlayClick = useCallback(event => {
    if (modalRef.current  && !modalRef.current.contains(event.target)) {
      close();
    }
  }, [close]);

  const onKeyUp = useCallback(event => {
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
      onOverlayClick(event);
    }
  }, [onOverlayClick]);

  return (
    // I don't really want to spend time reworking this modal to make it accessible since it's a custom component
    // Hence I'm just disabling the notices here as any fixes to add markup wouldn't be appropriate
    // This will be resolved whenever we get around to rewriting this app and using patternlibrary
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="test-invitation-modal-overlay" onClick={onOverlayClick}>
      <div
        className="test-invitation-modal-wrapper flex-columns"
        ref={modalRef}
        aria-modal
        role="dialog"

      >
        <div className="test-invitation-modal-content">
          <section>
            <div className="flex-rows flex-space-between">
              <h3 className="test-invitation-modal-heading">
                <Text id="productreviews-evaluatepage.testinvitationmodal.header" />
              </h3>
              <button className="close-button" onClick={close}>
                <img
                  alt={translations["productreviews-evaluatepage.testinvitationmodal.closeDialog"]}
                  src="https://cdn.trustpilot.net/icon-library/8.9.198/16x16_Miscellaneous/ic-cross.svg"
                  width="20"
                  height="20"
                />
              </button>
            </div>
          </section>
          <section>
            <p>
              <Text id="productreviews-evaluatepage.testinvitationmodal.description" />
            </p>
            <p>
              <Text id="productreviews-evaluatepage.testinvitationmodal.tryforreal" />
            </p>
          </section>
        </div>
        <div>
          <button className="test-invitation-modal-button" onClick={close}>
            <Text id={'productreviews-evaluatepage.testinvitationmodal.getproductreviews'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslations(TestInvitationModal);
