import { Location, navigate, Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { LocalizationProvider } from 'ilenia';
import 'lightbox-react/style.css';
import moment from 'moment';
import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import trackingLibrary from '../analytics/library';
import * as api from '../api';
import config from '../config.json';
import { bootstrap, edit, evaluate, flatten, isDesktop, isMs } from '../helper';
import localization from '../localization';
import '../styles/components.scss';
import '../styles/index.scss';
import '../styles/starSelector.scss';
import EditStartup from './EditStartup';
import ErrorPage from './ErrorPage';
import EvaluateStartup from './EvaluateStartup';
import MainPage from './MainPage';
import TestInvitationModal from './TestInvitationModal';
import Thanks from './Thanks';

const fallbackTranslations = {
  ...flatten(localization['en-US']),
};
const minimumReviewLength = 10;
const tracking = new trackingLibrary(config['TrackingApi.ApiKey']);

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 50, beforeChildren: 200 },
  exit: { opacity: 0 },
});

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <PoseGroup flipMove={false}>
        <RouteContainer key={location.key}>
          <Router location={location}>{children}</Router>
        </RouteContainer>
      </PoseGroup>
    )}
  </Location>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptsTerms: false,
      desktop: isDesktop(),
      error: null,
      isMs: isMs(),
      loading: true,
      ...(!this.props.linkId) && { error: new Error("Invalid URL"), loading: false },
    };

    this.closeTestInvitationModal = this.closeTestInvitationModal.bind(this);
    this.createOrActivateUser = this.createOrActivateUser.bind(this);
    this.postAttachment = this.postAttachment.bind(this);
    this.moment = this.moment.bind(this);
    this.postComment = this.postComment.bind(this);
    this.setAcceptsTerms = this.setAcceptsTerms.bind(this);
    this.submit = this.submit.bind(this);
    this.track = this.track.bind(this);
    this.ensureUserExists = this.ensureUserExists.bind(this);
  }

  render() {
    const { locale } = this.state;
    const translations = {
      ...flatten(localization[locale] || {})
    };

    let content;
    if (this.state.loading && this.props.editMode) {
      content = <EditStartup/>;
    } else if (this.state.loading) {
      content = <EvaluateStartup />;
    } else if (this.state.error) {
      content = <ErrorPage translationId="productreviews-evaluatepage.bootstrap.error" />;
    } else {
      const modePath = this.props.editMode ? edit : evaluate;
      content = (
        <div className="site-container">
          <div className="page-container">
            <PosedRouter>
              <ErrorPage
                path={`/${modePath}/:linkId/error`}
                translationId="productreviews-evaluatepage.submit.error"
              />
              <Thanks
                businessName={this.state.businessUnit.name}
                locale={locale}
                path={`/${modePath}/:linkId/thanks`}
                profileUrl={this.state.businessUnit.profileUrl}
                redirectUri={this.state.redirectUri}
                track={this.track}
                websiteUrl={this.state.businessUnit.websiteUrl}
              />
              <MainPage
                acceptsTerms={this.state.acceptsTerms}
                attachmentsEnabled={this.state.attachmentsEnabled}
                businessName={this.state.businessUnit.name}
                consumerEmail={this.state.consumer.email}
                consumerName={this.state.consumer.name}
                consumerUserExists={this.state.consumerUserExists}
                conversation={this.state.conversation}
                conversationPostInProgress={this.state.conversationPostInProgress}
                desktop={this.state.desktop}
                editMode={this.props.editMode}
                ip={this.state.requesterIp}
                isMs={this.state.isMs}
                isTestInvitation={this.state.isTestInvitation}
                locale={locale}
                minimumReviewLength={minimumReviewLength}
                moment={(dateTimeInput, formatString, relative) =>
                  this.moment(locale, dateTimeInput, formatString, relative)
                }
                path={`/${modePath}/:linkId`}
                postAttachment={this.postAttachment}
                postComment={this.postComment}
                productType={this.state.productType}
                products={this.state.products}
                selectedSku={this.props.selectedSku}
                setAcceptsTerms={this.setAcceptsTerms}
                source={this.state.source}
                submit={this.submit}
                track={this.track}
                ensureUserExists={this.ensureUserExists}
              />
              <TestInvitationModal
                close={this.closeTestInvitationModal}
                path={`/${modePath}/:linkId/test`}
              />
            </PosedRouter>
          </div>
        </div>
      );
    }

    return (
      <LocalizationProvider
        locale={locale}
        translations={{ ...fallbackTranslations, ...translations }}
      >
        {content}
      </LocalizationProvider>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ desktop: isDesktop() });
    });

    bootstrap(this.props.linkId, this.props.editMode)
      .then(({ attachmentsEnabled, businessUnit, consumer, conversation, products, ...data }) => {
        // We should not track data if it's a test invitation.
        // An easy way is overwritting the library functions with dummy ones:
        if (data.isTestInvitation) {
          tracking.page = () => { return; };
          tracking.identify = () => { return; };
          tracking.track = () => { return; };
        }

        tracking.identify(consumer.email);
        tracking.track('ProductReviewsEvaluatePageView', {
          businessUnitId: businessUnit.id,
          deviceWidth: window.innerWidth,
          deviceHeight: window.innerHeight,
          domain: businessUnit.domain,
          productsCount: products.length,
          attributesCount: products.reduce(
            (totalCount, currentReview) => totalCount + currentReview.attributeRatings.length,
            0
          ),
          source: data.source,
          attachmentsEnabled,
        });

        this.setState({
          ...data,
          attachmentsEnabled,
          businessUnit,
          consumer,
          conversation,
          loading: false,
          products,
        });
      })
      .catch((error) => this.setState({ error, loading: false }));
  }

  async closeTestInvitationModal() {
    await navigate(`/evaluate/${this.props.linkId}`);
  }

  async createOrActivateUser() {
    try {
      const ip = this.state.requesterIp;
      const response = await api.createOrActivateUser(this.props.linkId, ip);
      this.setState({
        accessToken: response.accessToken,
        consumerIsActive: true,
        consumerUserExists: true,
      });
      return response.accessToken;
    } catch (error) {
      Sentry.setTag("action", "createOrActivateUser");
      Sentry.captureException({
        error,
        message: 'Failed to create/activate user',
        json: JSON.stringify(error, Object.getOwnPropertyNames(error)) }
      );
      navigate(`/evaluate/${this.props.linkId}/error`);
      throw error;
    }
  }

  async ensureUserExists() {
    const { isTestInvitation, consumerUserExists, consumerIsActive } = this.state;

    if (!isTestInvitation && (!consumerUserExists || !consumerIsActive)) {
      return await this.createOrActivateUser();
    }
  }

  async postAttachment(accessToken) {
    return await api.postAttachment(accessToken || this.state.accessToken);
  }

  setAcceptsTerms(acceptsTerms) {
    this.setState({ acceptsTerms });
  }

  async submit(review, simplicity, accessToken) {
    const { isTestInvitation, source } = this.state;

    let response;
    try {
      if (this.props.editMode) {
        response = await api.postProductReviewsByEditLinkId(
          this.props.linkId,
          [review]
        );
      } else {
        response = await api.postProductReviews(
          this.props.linkId,
          [review],
          accessToken || this.state.accessToken,
          simplicity,
          isTestInvitation,
          source
        );
      }

      if (response.erroneous.length > 0) {
        throw response.erroneous[0].error;
      }
    } catch (error) {
      Sentry.setTag("action", "submit");
      Sentry.captureException({ error, message: 'Failed submit', json: JSON.stringify(error) });
      navigate(`/evaluate/${this.props.linkId}/error`);
      return;
    }

    const products = this.state.products.map((product) =>
      product.productId !== review.productId
        ? product
        : {
            ...product,
            ...review,
            isExistingProductReview: true,
          }
    );
    this.setState({ products });

    const modePath = this.props.editMode ? edit : evaluate;

    if (products.every(({ isExistingProductReview }) => isExistingProductReview)) {
      if (isTestInvitation) {
        await navigate(`/evaluate/${this.props.linkId}/test`);
      } else {
        await navigate(`/${modePath}/${this.props.linkId}/thanks`);
      }
    }

    return response;
  }

  track(name, data = {}) {
    const { domain, id } = this.state.businessUnit;
    tracking.track(name, {
      businessUnitId: id,
      domain,
      ...data,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  moment(locale, dateTimeInput, formatString, relative) {
    formatString = formatString || 'lll';
    const inputTime = new Date(dateTimeInput);
    const now = new Date();

    if (inputTime.getTime() > now.getTime()) {
      dateTimeInput = now;
    }

    const localeMoment = moment(dateTimeInput);
    localeMoment.locale(locale);

    if (relative) {
      return localeMoment.fromNow();
    } else {
      return localeMoment.format(formatString);
    }
  }

  async postComment(conversationComment) {
    this.setState({ conversationPostInProgress: true });

    try {
      await api.postComment(
        this.state.conversation.id,
        this.state.accessToken,
        conversationComment
      );
      this.track('PRReplySent');

      const conversation = await api.getConversation(
        this.state.conversation.id,
        this.state.accessToken
      );

      this.setState({
        conversation: conversation,
        conversationPostInProgress: false,
      });
    } catch (e) {
      this.setState({
        conversationPostInProgress: false,
      });
    }
  }
}

export default App;
