import axios from 'axios';
import pRetry from 'p-retry';
import * as Sentry from '@sentry/react';
import config from './config.json';

const get = (headers, url) => {
  const run = () =>
    fetch(config['TrustpilotApi.Host'] + url, { headers }).then((response) => {
      if (response.status === 404) {
        throw new pRetry.AbortError(response.statusText);
      }
      if (!response.ok) {
        throw response;
      }
      return response.json();
    });

  return pRetry(run, { retries: 5 });
};

const post = ({ headers, body, url, cache }) => {
  const run = () =>
    fetch(`${config['TrustpilotApi.Host']}${url}`, { body, headers, method: 'POST', cache }).then(
      (response) => {
        if (response.status === 404) {
          throw new pRetry.AbortError(response.statusText);
        }
        if (response.status === 401) {
          // force retries for Unauthorized
          throw new Error(`${response.statusText}`);
        }
        if (!response.ok) {
          throw new Error(`Remote API returned ${response.status} - ${response.statusText}. Request body was: ${JSON.stringify(body, null, 2)}`);
        }
        return response.json();
      }
    );

  return pRetry(run, { retries: 5 });
};

export const getAttributes = (locale = 'en-US') =>
  get(
    { apikey: config['TrustpilotApi.ApiKey'] },
    `/v1/product-attribute-ratings/attributes?locale=${locale}`
  )
    .then((response) => response.attributes)
    .then((attributes) =>
      attributes.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    );

export const getUserToken = (id) =>
  get({}, `/v1/auth/consumers/productinvitation?productReviewLinkId=${id}`);

export const getProductReviewByLinkId = (id, locale) =>
  get(
    { apikey: config['TrustpilotApi.ApiKey'] },
    `/v1/product-reviews/?linkid=${id}&locale=${locale}`
  );

export const getInvitationLinkData = (id) =>
  get(
    { apikey: config['TrustpilotApi.ApiKey'] },
    `/v1/product-reviews/invitation-links/${id}`
  ).then((response) => ({
    ...response,
    products: response.products.map((product) => ({
      ...product,
      attributes: product.attributes.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
    })),
  }));

const testBusinessUnit = {
  id: '000000000000000000000000',
  displayName: 'Test Company',
  name: {
    identifying: 'testcompany.com',
  },
  websiteUrl: 'https://www.trustpilot.com',
};

export const getBusinessUnit = (id, isTestInvitation = false) => {
  if (isTestInvitation) {
    return testBusinessUnit;
  }

  return get({ apikey: config['TrustpilotApi.ApiKey'] }, `/v1/business-units/${id}`);
};

const testBusinessUnitWebLinks = {
  profileUrl: 'https://www.trustpilot.com',
};

export const getBusinessUnitWebLinks = (id, locale, isTestInvitation = false) => {
  if (isTestInvitation) {
    return testBusinessUnitWebLinks;
  }

  return get(
    { apikey: config['TrustpilotApi.ApiKey'] },
    `/v1/business-units/${id}/web-links?locale=${locale}`
  );
};

export const createOrActivateUser = (id, ip) => {
  const data = {
    productReviewLinkId: id,
    ip: ip,
  };

  return post({
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
    url:'/v1/auth/consumers/productinvitation/consumer',
    cache: 'no-cache',
  });
};

export const test = () => 5;

const mapAttributeRatings = (attributeRatings) => {
  if (!attributeRatings) {
    return [];
  }

  // TODO: Here we are just not saving the suggested attributes (by filtering them out below),
  // but we need to take some action on them. Probably just tracking them, but it goes in another card.

  return attributeRatings
    .filter((attributeRating) => !attributeRating.suggestedByUser)
    .map((attributeRating) => {
      return {
        attributeId: attributeRating.attributeId,
        rating: attributeRating.rating > 0 ? attributeRating.rating : null,
      };
    });
};

const testPostProductReviewResponse = {
  erroneous: [],
  successful: [
    {
      response: {
        productReviewId: '000000000000000000000000',
      },
    },
  ],
};

export const postProductReviews = (
  linkId,
  productReviews,
  accessToken,
  simplicity,
  isTestInvitation,
  source
) => {
  if (isTestInvitation) {
    return testPostProductReviewResponse;
  }

  const requests = [];

  productReviews.forEach((productReview) => {
    const payload = {
      attachments: productReview.attachments,
      content: productReview.content,
      linkId: linkId,
      productId: productReview.productId,
      stars: productReview.stars,
      attributeRatings: mapAttributeRatings(productReview.attributeRatings),
      simplicity: simplicity,
      pastedContentLength: productReview.pasteValue,
      source,
    };

    requests.push(
      post({
        url: `/v1/private/product-reviews/?access_token=${accessToken}`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          return {
            response: response,
            payload: payload,
            error: null,
          };
        })
        .catch((error) => {
          return {
            response: null,
            payload: payload,
            error: error,
          };
        })
    );
  });

  return Promise.all(requests).then((responsesWrappers) => {
    const result = {
      erroneous: [],
      successful: [],
    };

    responsesWrappers.forEach((responsesWrapper) => {
      const response = responsesWrapper.response;

      if (response && response.productReviewId) {
        result.successful.push(responsesWrapper);
      } else {
        result.erroneous.push(responsesWrapper);
      }
    });

    return result;
  });
};

export const postProductReviewsByEditLinkId = (editLinkId, productReviews) => {
  const requests = [];

  productReviews.forEach((productReview) => {
    const payload = {
      attachments: productReview.attachments,
      content: productReview.content,
      editLinkId,
      stars: productReview.stars,
      attributeRatings: mapAttributeRatings(productReview.attributeRatings),
    };

    requests.push(
      fetch(
        `${config['TrustpilotApi.Host']}/v1/product-reviews/?apikey=${config['TrustpilotApi.ApiKey']}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify(payload),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw response;
          }

          return response.json();
        })
        .then((response) => {
          return {
            response: response,
            payload: payload,
            error: null,
          };
        })
        .catch((error) => {
          return {
            response: null,
            payload: payload,
            error: error,
          };
        })
    );
  });

  return Promise.all(requests).then((responsesWrappers) => {
    const result = {
      erroneous: [],
      successful: [],
    };

    responsesWrappers.forEach((responsesWrapper) => {
      const response = responsesWrapper.response;

      if (response && response.productReviewId) {
        result.successful.push(responsesWrapper);
      } else {
        result.erroneous.push(responsesWrapper);
      }
    });

    return result;
  });
};

export const getConversation = (id, accessToken) =>
  get({}, `/v1/private/conversations/${id}?access_token=${accessToken}`);

export const getEditLinkAuthToken = (id) =>
  get({ apikey: config['TrustpilotApi.ApiKey'] }, `/v1/product-reviews/auth/edit-link/${id}`);

export const getEditLinkData = (id) =>
  get({ apikey: config['TrustpilotApi.ApiKey'] }, `/v1/product-reviews/edit-links/${id}`);

export const postAttachment = (accessToken) =>
  fetch(
    `${config['TrustpilotApi.Host']}/v1/private/product-reviews/attachments?access_token=${accessToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  ).then((response) => response.json());

export const putAttachment = (preSignedUrl, body, mimeType, onUploadProgress) =>
  axios.put(preSignedUrl, new Blob([body], { type: mimeType }), {
    onUploadProgress,
    headers: {
      'Content-Type': mimeType,
    },
  });

export const postComment = (conversationId, accessToken, conversationComment) => {
  return fetch(
    `${config['TrustpilotApi.Host']}/v1/private/conversations/${conversationId}/comments?access_token=${accessToken}`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ content: conversationComment }),
    }
  );
};
