import { Text } from 'ilenia';
import React, { useState } from 'react';

const TestInvitationBanner = () => {
  const [visible, setVisible] = useState(true);

  return visible && (
    <div className="test-invitation-banner">
      <div className="test-invitation-banner-message">
        <Text id="productreviews-evaluatepage.testinvitationbanner" />
      </div>
      <button
        className="test-invitation-banner-close-button"
        onClick={() => setVisible(false)}
      >
        <span>✕</span>
      </button>
    </div>
  );
};

export default TestInvitationBanner;
