import React from 'react';

const StarImage = ({ size, rating }) => (
  <img
    className={`product-stars__image product-stars__image--${size}`}
    src={`https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${rating}.svg`}
    alt={`Rating: ${rating}`}
  />
);

const StarSelector = ({ onChange, stars, name, size = 'large', disabled = false }) => {
  const [hoveredRating, setHoveredRating] = React.useState(null);
  const displayRating = hoveredRating || stars || 0;

  return (
    <div
      className={`product-stars product-stars--${disabled ? 'disabled' : 'enabled'}`}
      onMouseOut={() => setHoveredRating(null)}
      onBlur={() => setHoveredRating(null)}
    >
      <div className={`product-stars__input product-stars__input--${size}`}>
        {[1, 2, 3, 4, 5].map((rating) => (
          <input
            key={rating}
            type="radio"
            name={name}
            value={rating}
            disabled={disabled}
            className={`star star--${rating} star--${size}`}
            onChange={() => {
              onChange(rating);
            }}
            onMouseOver={() => setHoveredRating(rating)}
            onFocus={() => setHoveredRating(rating)}
            aria-label={`${rating} stars`}
          />
        ))}
      </div>
      <StarImage size={size} rating={displayRating} />
    </div>
  );
};
export default StarSelector;
