import React, { Component } from 'react';

import posed from 'react-pose';
import { Text } from 'ilenia';
import Confetti from 'react-dom-confetti';

import TrustpilotHeader from './TrustpilotHeader';

const Container = posed.div({
  enter: { staggerChildren: 50 },
});

const styleContainer = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const P = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 },
});

const PosedP = posed.p({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 },
});

const PosedImg = posed.img({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 },
});

class Thanks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activateConfetti: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ activateConfetti: true });
    }, 600);
  }

  render() {
    const { businessName, profileUrl, redirectUri, websiteUrl } = this.props;

    if (redirectUri) {
      window.location = redirectUri;
      return null;
    }

    return (
      <Container className="thank-you" style={styleContainer}>
        <P style={{ width: '100%' }}>
          <TrustpilotHeader />
        </P>

        <PosedImg
          id="tp-logo-img"
          src="/assets/Trustpilot_star_green_RGB.svg"
          className="logo__thanks"
          title="Trustpilot"
        />

        <P className="thanks-header">
          <Text id="productreviews-evaluatepage.thankyoupage.feelsgood" />
        </P>

        <Confetti active={this.state.activateConfetti}  />

        <PosedP className="thanks-paragraph">
          <Text id="productreviews-evaluatepage.thankyoupage.thanksforhelping" />
        </PosedP>

        <P className="thanks-link">
          <a href={profileUrl}>
            <Text
              id="productreviews-evaluatepage.thankyoupage.seecompanyontrustpilot"
              interpolations={{ COMPANY: businessName }}
              tag={{ start: '{', end: '}' }}
            />
          </a>
        </P>

        <P className="thanks-link">
          <a href={websiteUrl}>
            <Text
              id="productreviews-evaluatepage.thankyoupage.visitcompany"
              interpolations={{ COMPANY: businessName }}
              tag={{ start: '{', end: '}' }}
            />
          </a>
        </P>
      </Container>
    );
  }
}

export default Thanks;
