import * as api from './api';
import urlParse from 'url-parse';
import { Buffer } from 'buffer';

import trustpilotPackageBlue from './assets/Trustpilot-Package-Blue.jpg';
import trustpilotPackagePink from './assets/Trustpilot-Package-Pink.jpg';
import trustpilotPackagePurple from './assets/Trustpilot-Package-Purple.jpg';
import trustpilotPackageRed from './assets/Trustpilot-Package-Red.jpg';
import * as Sentry from "@sentry/react";

export const edit = 'edit';
export const evaluate = 'evaluate';

export const fallbackImageUrls = [
  trustpilotPackageBlue,
  trustpilotPackagePink,
  trustpilotPackagePurple,
  trustpilotPackageRed,
];

export const AttributeTypes = {
  Default: 'range_1to5',
  Scale: 'scale',
};

const testInvitationLinkId = '00000000000000000000000000000000';

const hasHash = (path) => path.includes('/#');
const removeHashFromPath = (path) => path.replace(/#\/?/, '');

const uriPattern =
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
const isValidUri = (uri) => uri && uriPattern.test(uri);

const normalizeLocale = (locale) =>
  locale && locale.length === 5
    ? `${locale.slice(0, 3).toLowerCase()}${locale.slice(3, 5).toUpperCase()}`
    : locale;

export const extractUrlInfo = (url) => {
  const parsed = urlParse(removeHashFromPath(url), location, true);

  if (hasHash(url)) {
    location.replace(parsed.toString());
  }

  let separator;
  if (url.includes(edit)) {
    separator = edit;
  } else if (url.includes(evaluate)) {
    separator = evaluate;
  } else {
    Sentry.setTag("action", "extractUrlInfo");
    Sentry.captureException({
      error: `Invalid URL: ${url}`,
      location: JSON.stringify(location),
      referrer: Document.referrer
    });
    return {
      editMode: false,
      linkId: null,
    }
  }

  const tokenizedPath = parsed.pathname.split('/');
  const linkId = tokenizedPath[tokenizedPath.indexOf(separator) + 1];

  const selectedSku = parsed.query.sku;

  return {
    editMode: separator === edit,
    linkId,
    selectedSku,
  };
};

export const flatten = (object, prefix = '') =>
  Object.keys(object).reduce(
    (prev, element) =>
      object[element] && typeof object[element] === 'object' && !Array.isArray(object[element])
        ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
        : { ...prev, ...{ [`${prefix}${element}`]: object[element] } },
    {}
  );

const getDataWithLinkId = async (linkId, isTestInvitation) => {
  const [invitationLinkData, userToken] = await Promise.all([
    api.getInvitationLinkData(linkId),
    api.getUserToken(linkId),
  ]);

  const businessUnitId = invitationLinkData.businessUnitId;
  const locale = normalizeLocale(invitationLinkData.locale);
  const [productReviews, businessUnitResponse, businessUnitWebLinks] = await Promise.all([
    api.getProductReviewByLinkId(linkId, locale),
    api.getBusinessUnit(businessUnitId, isTestInvitation),
    api.getBusinessUnitWebLinks(businessUnitId, locale, isTestInvitation),
  ]);

  const businessUnit = {
    id: businessUnitResponse.id,
    name: businessUnitResponse.displayName,
    websiteUrl: businessUnitResponse.websiteUrl,
    domain: businessUnitResponse.name.identifying,
    country: businessUnitResponse.country,
    profileUrl: businessUnitWebLinks.profileUrl,
  };

  return [invitationLinkData, productReviews, userToken, businessUnit, locale];
};

const getDataWithEditLinkId = async (linkId) => {
  const [dataResponse, authResponse] = await Promise.all([
    api.getEditLinkData(linkId),
    api.getEditLinkAuthToken(linkId),
  ]);

  const { businessUnitId, conversationId } = dataResponse.productReviews[0];
  const locale = normalizeLocale(dataResponse.productReviews[0].locale);
  const promises = [
    api.getBusinessUnit(businessUnitId),
    api.getBusinessUnitWebLinks(businessUnitId, locale),
  ];
  if (conversationId) {
    promises.push(api.getConversation(conversationId, authResponse.accessToken));
  }
  const [businessUnitResponse, businessUnitWebLinks, conversationResponse] = await Promise.all(
    promises
  );

  const businessUnit = {
    id: businessUnitResponse.id,
    name: businessUnitResponse.displayName,
    websiteUrl: businessUnitResponse.websiteUrl,
    country: businessUnitResponse.country,
    domain: businessUnitResponse.name.identifying,
    profileUrl: businessUnitWebLinks.profileUrl,
  };

  return [dataResponse, authResponse, businessUnit, locale, conversationResponse];
};

export const isDesktop = () => window.innerWidth >= 48 * 16;

export const isMs = () => {
  // source: https://stackoverflow.com/a/49328524/1417098
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  return isIE || isEdge;
};

const linkProductWithReview = (products, productReviews) =>
  products.map((product) => {
    const productReview = productReviews.find(
      (productReview) => productReview.productId === product.productId
    );
    return Object.assign({}, product, productReview, {
      isExistingProductReview: !!productReview,
    });
  });

const reMapAttributeRatings = (product) => {
  const attributeRatings = product.isExistingProductReview
    ? product.attributeRatings.map((attr) => {
        if (attr.rating === null) {
          attr.rating = 0;
          attr.notApplicable = true;
        }

        attr.attributeType ??= AttributeTypes.Default;

        return attr;
      })
    : product.attributes.map((attr) => ({
        attributeId: attr.id,
        attributeName: attr.name,
        attributeType: attr.type || AttributeTypes.Default,
        attributeOptions: attr.options,
        rating: 0,
        notApplicable: false,
        suggestedByUser: false,
        definedByUser: false,
      }));

  const groupedAttributeRatings = attributeRatings.reduce(
    (acc, curr) => {
      if (curr.attributeType === AttributeTypes.Default) {
        acc.defaults.push(curr);
      } else {
        acc.scales.push(curr);
      }

      return acc;
    },
    { defaults: [], scales: [] }
  );

  const sortedAttributeRatings = [
    ...groupedAttributeRatings.defaults.sort((a, b) =>
      a.attributeName.localeCompare(b.attributeName)
    ),
    ...groupedAttributeRatings.scales.sort((a, b) =>
      a.attributeName.localeCompare(b.attributeName)
    ),
  ];

  delete product.attributes;
  delete product.attributeRatings;
  return Object.assign({}, product, { attributeRatings: sortedAttributeRatings });
};

export const bootstrap = (linkId, editMode) => {
  if (editMode) {
    return getDataWithEditLinkId(linkId).then(
      ([{ productReviews }, { accessToken }, businessUnit, locale, conversation]) => {
        const [
          {
            attributeRatings,
            consumer,
            product: { id: productId, ...product },
            ...productReview
          },
        ] = productReviews;
        const mergedReview = {
          ...product,
          ...productReview,
          attributeRatings: attributeRatings.map((attributeRating) => ({
            ...attributeRating,
            notApplicable: attributeRating.rating === null,
            rating: attributeRating.rating || 0,
            attributeType: attributeRating.type || AttributeTypes.Default,
          })),
          fallbackImageUrl: fallbackImageUrls[0],
          isExistingProductReview: true,
          productId,
        };

        return {
          accessToken,
          businessUnit,
          consumer,
          consumerIsActive: true,
          consumerUserExists: true,
          conversation,
          isTestInvitation: false,
          linkId,
          locale,
          productType: attributeRatings && attributeRatings.length > 0 ? 'product' : 'experience',
          products: [mergedReview],
          redirectUri: null,
        };
      }
    );
  } else {
    const isTestInvitation = linkId === testInvitationLinkId;
    return getDataWithLinkId(linkId, isTestInvitation).then(
      ([
        { attachmentsEnabled, consumer, products, productType, redirectUri, requesterIp, source },
        { productReviews },
        userToken,
        businessUnit,
        locale,
      ]) => {
        const productsWithReviews = linkProductWithReview(products, productReviews)
          .map(reMapAttributeRatings)
          .map((product, index) => {
            product.fallbackImageUrl = fallbackImageUrls[index % fallbackImageUrls.length];
            return product;
          });

        const jwtToken = userToken.jwtToken;
        const tokenParts = jwtToken.split('.');
        const tokenPayload = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString('utf8'));

        const accessToken = userToken.accessToken;
        const consumerUserExists = tokenPayload.consumerExists;
        const consumerIsActive = tokenPayload.payload && tokenPayload.payload.isActive;

        const isExperience = new RegExp(
          'Event|LocalBusiness|Place|LodgingBusiness|FoodEstablishment'
        );
        productType = isExperience.test(productType) ? 'experience' : 'product';

        return {
          accessToken,
          attachmentsEnabled,
          businessUnit,
          consumer,
          consumerIsActive,
          consumerUserExists,
          isTestInvitation,
          linkId,
          locale,
          userToken,
          products: productsWithReviews,
          productType,
          redirectUri: isValidUri(redirectUri) ? redirectUri : null,
          requesterIp: requesterIp || '127.0.0.1',
          source,
        };
      }
    );
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const extractFrame = async (file) => {
  const src = await toBase64(file);
  const source = document.createElement('source');
  source.src = src;
  source.type = 'video/mp4'; // force type to mp4 for <video> to be able to render content

  const video = document.createElement('video');
  video.appendChild(source);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  await new Promise((resolve) => {
    video.addEventListener('loadedmetadata', resolve);
  });
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  video.muted = true;
  await video.play();
  await new Promise((resolve) => {
    video.addEventListener('timeupdate', resolve);
  });
  video.pause();
  ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  return canvas.toDataURL();
};

export const getMediaType = (type) =>
  type?.startsWith('video') ? 'video' : type?.startsWith('image') ? 'image' : 'unknown';

export const findBestProductImage = (productImages) => {
  let largeImage;
  const productImage = productImages
    ? ((largeImage = productImages.find((x) => x.type === '300pxWide')), largeImage)
      ? largeImage
      : productImages.find((x) => x.type === '100pxWide')
    : null;

  return productImage;
};
