import React from 'react';
import { Text, LinkText } from 'ilenia';

const AcceptTerms = ({
  checked = false,
  keyPrefix,
  messageVisible,
  onChange,
  privacyPolicy = 'https://legal.trustpilot.com/end-user-privacy-terms',
  termAndConditions = 'https://legal.trustpilot.com/end-user-terms-and-conditions',
}) => {
  return (
    <div className="accept-terms">
      <input
        type="checkbox"
        name="acceptTerms"
        id={`${keyPrefix}-accept-terms`}
        checked={checked}
        onChange={onChange}
      />
      { /* This markup and styling doesn't look like something I want to mess with so I'm disabling this lint */}
      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
      <label htmlFor={`${keyPrefix}-accept-terms`} className="accept-terms-style" />
      <div htmlFor={`${keyPrefix}-accept-terms`} className="accept-terms-label">
        <LinkText
          id="productreviews-evaluatepage.termsaccept"
          links={[
            {
              href: termAndConditions,
              target: '_blank',
              start: '[TermsLink-Begin]',
              end: '[TermsLink-End]',
            },
            {
              start: '[PrivacyLink-Begin]',
              end: '[PrivacyLink-End]',
              href: privacyPolicy,
              target: '_blank',
            },
          ]}
        />
      </div>
      {messageVisible && (
        <p className="missing-accept-terms-message">
          <Text id="productreviews-evaluatepage.termsacceptMessage" />
        </p>
      )}
    </div>
  );
};

export default AcceptTerms;
